<template>
    <div class="container">
        <h1 class="has-text-primary has-text-weight-bold is-size-1-mobile" :class="displayOnTouch ? 'is-size-huge-mobile' : 'is-size-huge'">
            Comment<br>ça marche ?
        </h1>

        <div class="entrance columns is-4 is-variable">
                <div class="column" @click="show = true;toggleSize=0;goto()">
                    
                <figure  class="image"  :class="toggleSize ==0 ? 'is-regularImage' : 'is-smallImage'">
                <img :src="require('./../../assets/main.jpg')" alt="JeStockeMonBateau.fr - je suis bateau stockeur" title="JeStockeMonBateau.fr - je suis bateau stockeur">
                <caption class="is-size-3 has-text-weight-bold is-uppercase has-text-white">
                    Je suis<br>
                    bateau <span class="has-text-primary">stockeur</span></caption>
                </figure>
                </div>
            <div class="column" @click="show = false;toggleSize=1">
                <figure @click="goto()" class="image"  :class="toggleSize ==1 ? 'is-regularImage' : 'is-smallImage'">
                  <img :src="require('./../../assets/earning.jpg')" alt="JeStockeMonBateau.fr - je suis bateau Skipper" title="JeStockeMonBateau.fr - je suis bateau Skipper">
                  <caption class="is-size-3 has-text-weight-bold is-uppercase has-text-white">
                      Je suis<br>
                      bateau <span class="has-text-primary">Skipper</span></caption>
                </figure>
            </div>
        </div>
        

    <div id="contain-tl">
        <div class="timeline-container" v-if="show">
            <div class="columns">
                <div class="column">
                    <p>Je suis un particulier ou un professionnel, je possède un espace de stockage (terrain, place au port, entrepôt, garage …) et souhaite me dégager un revenu supplémentaire en proposant à la location mes m² inexploités.</p>
                    <br>
                    <p>Je deviens bateau stockeur en quelques clics !</p>
                    <strong>Rien de plus simple … laissez-vous guider !</strong>
                    <br>
                </div>
                <div class="column"></div>
            </div>
            <div class="timeline" :class="{'is-centered':!displayOnTouch}">
                <header class="timeline-header">
                    <span class="tag is-large is-size-4 is-primary has-text-weight-bold is-uppercase" :class="{'is-size-6':displayOnTouch}">Commenceons !</span>
                </header>
                <div class="timeline-item is-primary">
                    <div class="timeline-marker is-primary"></div>
                    <div class="timeline-content">
                        <p class="heading is-size-4">Je publie mon annonce gratuitement</p>
                        <p>Je renseigne les informations générales liées à mon offre locative :</p>
                        <br>
                        <ul>
                            <li>Suis-je un particulier ou un professionnel ?</li>
                            <li>Où est située ma location ?</li>
                            <li>Qu’est-ce que je souhaite stocker :</li>
                            <ul>
                                <li>un bateau et une remorque,</li>
                                <li>uniquement une remorque,</li>
                                <li>…</li>
                            </ul>
                        </ul>
                        <br>
                        <p>Je donne également des précisions sur le type d’espace proposé <span class="tag is-light">(terrain, garage…)</span> ainsi que sur le nombre de places disponibles <span class="tag is-light">(estimation de la surface)</span>, ou encore la distance au lieu de mise à l’eau…</p>
                        <br>
                        <p> Nous vous conseillons de mettre en avant toutes options relatives à la sécurité, qui rassureront le client potentiel.
                           J’intègre des photos et détaille mes conditions <span class="tag is-light">(tarifs, coordonnées …etc)</span>.</p>
                        <br>
                        <div class="notification">Plus votre profil est fourni plus vous augmentez vos chances de trouver un locataire rapidement !</div>
                        <br>
                        <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">Hop, la 1ere étape est déjà terminée !</h3>
                    </div>
                </div>


                <div class="timeline-item is-primary">
                    
                    <div class="timeline-content">
                        <p class="heading is-size-4">Je suis contactée par d’éventuels futurs locataires (bateau skippers)</p>
                        <p>Et j’échange librement avec eux.</p>
                        <br>
                        <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">Soyez réactif aux demandes !</h3>
                    </div>
                    <div class="timeline-marker is-primary marker-left"></div>
                </div>

                <div class="timeline-item is-primary">
                    <div class="timeline-marker is-primary"></div>
                    <div class="timeline-content">
                        <p class="heading is-size-4">Je choisis mon bateau skipper</p>
                        <p>Après quelques échanges et en étudiant sa proposition, vous êtes décidé ; ce sera lui votre bateau skipper !</p>
                        <br>
                        <div class="notification">Il fait alors sa réservation en ligne que vous validez.<br><strong>Vous recevez un mail de confirmation.</strong></div>
                        <br>
                        <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">L’aventure peut commencer !</h3>
                    </div>
                </div>


                <div class="timeline-item is-primary">
                    <div class="timeline-marker is-primary marker-left"></div>
                    <div class="timeline-content">
                    <p class="heading is-size-4">Je loue en toute tranquillité</p>
                    <p>Je rencontre le bateau skipper et réalisons ensemble<br>l’état des lieux avant de signer le contrat.</p>
                    <br>
                    <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">C’est officiel vous êtes bateau-stockeur.</h3>

                    </div>
                </div>
                <header class="timeline-header">
                    <span class="tag is-large is-size-4 is-primary has-text-weight-bold is-uppercase" :class="{'is-size-6':displayOnTouch}">THE End ;)</span>
                </header>
            </div>
        </div>

        <div class="timeline-container" v-if="!show && show != null">

            <div class="columns">
                <div class="column is-6"></div>
                <div class="column is-6 has-text-left">
                    <p>Je possède un bateau et suis à la recherche d’une solution simple, peu chère et sécurisée pour faire garder mon bateau ?<p>
                    <p>Je consulte Jestockmonbateau.fr et trouve l’offre de stockage qui correspond le mieux à mes attentes !</p>
                    <br>
                    <strong>Je suis bateau skipper !</strong>
                    <br>
                </div>
                <div class="column"></div>
            </div>
            <div class="timeline" :class="{'is-centered':!displayOnTouch}">
                <header class="timeline-header">
                    <span class="tag is-large is-size-4 is-primary has-text-weight-bold is-uppercase" :class="{'is-size-6':displayOnTouch}">Allons-y !</span>
                </header>
                <div class="timeline-item is-primary">
                    <div class="timeline-marker is-primary"></div>
                    <div class="timeline-content">
                        <p class="heading is-size-4">Je consulte les annonces</p>
                        <p>Je recherche mon futur espace de stockage en fonction de la localisation, des dates et du type d’espace souhaités (je préfère stockeur mon bateau sur un terrain, ou à l’inverse dans un entrepôt …)</p>
                        <br>
                        <p>Je précise la taille de mon bateau afin d’établir une estimation des m² nécessaires.</p>
                        <p>Seul ou à partager ? Vous avez également la possibilité de bénéficier d’une location privée ou collective (avec d’autres bateau skippers).</p>
                        <br>
                        <p> Je compare les offres répondant à mes critères et peux entrer en contact avec des bateaux stockeurs afin de poser toutes les questions que je juge utiles.</p>
                        <br>
                        <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">Nous vous conseillons d’en contacter plusieurs afin de trouver votre espace le plus rapidement !</h3>
                    </div>
                </div>


                <div class="timeline-item is-primary">
                    <div class="timeline-marker is-primary marker-left"></div>
                    <div class="timeline-content">
                        <p class="heading is-size-4">J’effectue une<br>demande de réservation</p>
                        <p>J’ai trouvé le bateau-skipper qu’il me faut…</p>
                        <br>
                        <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">je réserve.</h3>
                    </div>
                </div>

                <div class="timeline-item is-primary">
                    <div class="timeline-marker is-primary"></div>
                    <div class="timeline-content">
                        <p class="heading is-size-4">Je paie en ligne</p>
                        <p>Le bateau-stockeur accepte ma demande. Je paie en ligne via notre service de paiement 100% sécurisé.</p>
                        <br>
                        <router-link tag="a" class="button is-primary" :to="{name:'Conditions'}"> Voir les conditions</router-link>
                        <br>
                        <br>
                        <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">L’aventure peut commencer !</h3>
                    </div>
                </div>


                <div class="timeline-item is-primary">
                    <div class="timeline-marker is-primary marker-left"></div>
                    <div class="timeline-content">
                    <p class="heading is-size-4">Je stocke mon bateau</p>
                    <p>Je rencontre le bateau stockeur et nous établissons un état des lieux et signons le contrat.</p>
                    <br>
                    <h3 class="has-text-weight-bold is-uppercase has-text-primary is-size-4">Je suis serein mon bateau est entre de bonnes mains !</h3>

                    </div>
                </div>
                <header class="timeline-header">
                    <span class="tag is-large is-size-4 is-primary has-text-weight-bold is-uppercase" :class="{'is-size-6':displayOnTouch}">THE End ;)</span>
                </header>
            </div>
        </div>
    </div>


    </div>
</template>
<script>
import 'bulma-timeline/dist/css/bulma-timeline.min.css'
export default {
    name: 'Howitworks',
    data() {
        return {
            show:null,
            toggleSize:null,
        }
    },
    methods: {
        goto() {
            setTimeout(function(){
                var element = document.getElementById("contain-tl");
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }, 500);
        }
	},
    metaInfo () {
		let siteName = this.$store.state.globalConstant.SITE_NAME,
			baseUrl = this.$store.state.globalConstant.BASE_URL,
			canonical = this.$router.resolve({ name: 'Howitworks' })

		let title = (siteName+' - Comment ça marche ? - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
			description = (siteName+' Jestockemonbateau est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! Que vous soyez bateau-stocker ou bateau-skipper découvrez comment fonctionne jestockemonbateau.fr étapes par étapes afin de répondres à vos besoins de stockage, hivernage bateau.')

		return {
			// if no subcomponents specify a metaInfo.title, this title will be used
			titleTemplate: title,
			link: [
				{ rel: 'canonical', href: (baseUrl+canonical.href) },
			],
			meta: [
				{ 
					vmid: 'description', 
					name: 'description', 
					content: description
				},
				{
					'property': 'og:title',
					'content': title,
					'vmid': 'og:title'
				},
				{
					'property': 'og:description',
					'content': description,
					'vmid': 'og:description'
				},
				{
					'property': 'og:url',
					'content': (baseUrl+this.$route.fullPath),
					'vmid': 'og:url'
				},
				{
					'property': 'og:image',
					'content': (baseUrl+require('./../../assets/og-image.png')),
					'vmid': 'og:image'
				},
				{
					'property': 'og:image:secure_url',
					'content': (baseUrl+require('./../../assets/og-image.png')),
					'vmid': 'og:image:secure_url'
				},
				{
					'property': 'twitter:title',
					'content': title,
					'vmid': 'twitter:title'
				},
				{
					'property': 'twitter:description',
					'content': description,
					'vmid': 'twitter:description'
				},
				{
					'property': 'og:image:alt',
					'content': title,
					'vmid': 'og:image:alt'
				},
				{
					'property': 'twitter:image',
					'content': (baseUrl+require('./../../assets/tw-card.jpg')),
					'vmid': 'twitter:image'
				}
			]
		}
	}	
}
</script>
<style scoped>
.container {
    max-width: 90%;
    /* margin: 5% 5%; */
}
.container h1{
    margin: 5% 0;
}
.is-size-huge {
    font-size: 8rem;
    line-height: 6rem;
}
.is-size-huge-mobile{
    font-size: 6rem;
    line-height: 4rem;
}
figure {
    position:relative
}
caption {
    position: absolute;
    text-align: left;
    bottom: 1rem;
    left: 1rem;
}
.is-size-3 {
    line-height: 2rem;
}
.entrance {
    margin-bottom: 2rem;
}
.entrance .column:nth-child(odd) figure {
    box-shadow: -20px -20px 0px 0px rgba(39,198,189,1);
}
.entrance .column:nth-child(even) figure {
    box-shadow: 20px 20px 0px 0px rgba(39,198,189,1);
}
.timeline {
    margin: 2rem 0
}
.timeline-container {
    padding: 5rem 0
}
figure {
    transition: all ease 0.5s !important;
}
.is-regularImage {
    transform: scale(1);
    opacity: 1;
}
.is-smallImage {
    transform: scale(0.9);
    opacity: 0.8;
}
.heading {
    font-size:1rem;
}
li {
    list-style-position: inside;
    font-size: 0.9rem;
    padding-left: 1rem;
}
li:before { 
    content: "";
    border-color: transparent #27c6bd;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 1em;
    position: relative;
}
.tag {
    border-radius: 4px
}
@media screen and (max-width:768px) {
    .container h1{
        font-size: 3rem;
    }
    .timeline .timeline-header {
        justify-content: left
    }
}

.marker-left {
    float: right;
    right: 0px;
}

@media screen and (max-width:1049px) {
    .marker-left {
        float: left;
        left: 0px;
    }
}
</style>
